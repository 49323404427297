<template>
  <section class="section">
    <loader-hummingbird :delay="1000" v-if="loading"></loader-hummingbird>
    <div class="container">
      <h2 class="is-size-3 has-margin-bottom">Seller Services</h2>
      <div v-if="sellerServices && sellerServices.length > 0">
        <div class="columns is-multiline">
          <div class="column is-4" v-for="service in sellerServices" :key="service.id">
            <el-card shadow="hover">
              <div v-if="service.previews && service.previews.length > 0">
                <div v-if="service.previews[0].type === PREVIEW_TYPE.VIDEO" class="has-text-centered">
                  <video :src="service.previews[0].value" style="height: 200px;"
                    preload="true" playsinline="true" autoplay="true" loop="true" muted="muted"
                  >
                  </video>
                </div>
                <div v-if="service.previews[0].type === PREVIEW_TYPE.IMAGE">
                  <el-image :src="service.previews[0].value" fit="contain"
                    style="width: 100%; height: 200px"
                  >
                  </el-image>
                </div>
              </div>
              <h3 class="is-size-6 has-margin-top has-font-alternate has-text-weight-bold">{{ service.title }}</h3>
              <p class="is-size-6-7">{{ serviceAmountString(service.amount) }}</p>
              <router-link :to="`/service/${service.customLink ? service.customLink.id : service.id}`">
                <el-button type="text">View Service</el-button>
              </router-link>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import {
  API,
  graphqlOperation,
} from 'aws-amplify'
import { listServices } from '@/graphql/queries'
import { getSellerServicesEndpoint } from '@/api/services'
import LoaderHummingbird from '@/components/LoaderHummingbird.vue';
import { PREVIEW_TYPE } from '@/graph-constants';

export default {
  name: 'Seller',
  components: {
    LoaderHummingbird,
  },
  data() {
    return {
      PREVIEW_TYPE,
      sellerId: this.$route.params.id,
      sellerServices: null,
      loading: true,
    }
  },
  async created() {
    if (this.$store.state.user) {
      const sellerServicesResponse = await API.graphql(graphqlOperation(
        listServices,
        {
          limit: 1000,
          filter: {
            owner: { eq: this.sellerId }
          }
        },
      ))
      this.sellerServices = sellerServicesResponse.data.listServices.items
    } else {
      const sellerServicesResponse = await axios.post(getSellerServicesEndpoint, { sellerId: this.sellerId })
      this.sellerServices = sellerServicesResponse.data
    }
    this.loading = false
  },
  methods: {
    serviceAmountString(amount) {
      if (typeof amount === 'number') {
        if (amount === 0) {
          return 'Free'
        } else if (Math.floor(amount / 100) === amount / 100) {
          return `$${(amount / 100).toFixed(0)}`
        }
        return `$${(amount / 100).toFixed(2)}`
      }
      return ''
    },
  }
}
</script>
